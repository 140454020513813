@import url('https://fonts.googleapis.com/css2?family=Protest+Strike&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');

*{
    box-sizing: border-box;
}

body{
    background-color: #E9EAEC;
}

.bgr{
  background-image: url("./logobg.png");
}


@font-face {
    font-family: thermite;
    src: url(./FontsFree-Net-Thermite.woff2);
}

@font-face {
  font-family: inter;
  src: url(./FontsFree-Inter.woff2);
}

.interf{
    font-family: inter;
}


.protest{
    font-family: "Protest Strike", sans-serif;
    font-weight: 400;
    font-style: normal;
  }

  .anton {
    font-family: "Anton", sans-serif;
    text-transform: none;
  }
  
.thermite{
    font-family: thermite;
}

.cus::before {
    content: "";
    height: 40px;
    width: 40px;
    background-color: transparent;
    position: absolute;
    left: -40px;
    top:0;
    border-top-right-radius: 50px;
    box-shadow: 10px -10px 0 10px #E9EAEC;
    
}

.cus::after {
    content: "";
    height: 40px;
    width: 40px;
    background-color: transparent;
    position: absolute;
    top:0;
    right: -40px;
    border-top-left-radius: 50px;
    box-shadow: -10px -10px 0 10px #E9EAEC;
}



.cus{
    font-family: thermite;
}

.screen{
    mix-blend-mode: screen;
}

.colorburn{
    mix-blend-mode:difference;
}

.hero {
    background: rgba(0, 0, 0, 0.5) url("./hro.jpg") no-repeat center / cover;
}


.cusa {
    cursor: pointer;
    position: relative;
    display: inline-block;
    letter-spacing: 0.05rem;
}


.cusa:after{
    content: "";
    background-color: red;
    width: 0;
    height: 2px;
    position: absolute;
    border-radius: 50px;
    left: calc(34%); 
    bottom: -2px ;
    opacity: 0.9;
    transition: 0.1s;

}

.cusa:hover:after,
.cusa.selected::after {
    width: 30%;
}




.cus2::before {
    box-shadow: 10px -10px 0 10px #171717;
  }
  
  .cus2::after {
    box-shadow: -10px -10px 0 10px #171717;
  }

  .smallbann{
    background: url('https://cdn.onefc.com/wp-content/uploads/2022/08/live-in-hd-one-on-prime-video-1-moraes-vs-johnson-ii-press-conference-1.jpg') no-repeat center /cover ;
    filter: brightness(0.7);
  }



.mobafter{
    margin-top: -80px;
}


  .mobafter:after{
    content: "";
    height: 20px;
    width: 20px;
    background-color: transparent;
    position: absolute;
    bottom: -16px;
    left: 1px;
    box-shadow: -17px -10px 0px 10px #171717;
    border-top-left-radius: 50px;
  }

  .mobafter:before{
    content: "";
    height: 20px;
    width: 20px;
    background-color: transparent;
    position: absolute;
    top: -16px;
    left: 1px;
    box-shadow: -17px 10px 0px 10px #171717;
    border-bottom-left-radius: 50px;
  }


  .ham {
    color: #ffffff;
    background: none;
    width: 100px;
    height: 56px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .ham svg {
    fill: none;
    stroke: #ffffff;
    stroke-width: 15px;
    stroke-linecap: round;
    stroke-linejoin: round;
    height: 30px;
    width: 30px;
    margin-top: 5px;
  }
  
  .ham svg use:nth-of-type(1) {
    opacity: 1;
    stroke-dashoffset: 221;
    stroke-dasharray: 46 249;
    transition: stroke-dashoffset .12s linear .2s, stroke-dasharray .12s linear .2s, opacity 0s linear .2s;
  }
  
  .ham svg use:nth-of-type(2) {
    animation: stroke-animation-reverse 1.2s ease-out forwards;
  }
  
  .ham input {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 2;
    cursor: pointer;
    opacity: 0;
  }
  
  .ham input:checked + svg use:nth-of-type(1) {
    stroke-dashoffset: 175;
    stroke-dasharray: 0 295;
    opacity: 0;
    transition: stroke-dashoffset .07s linear .07s, stroke-dasharray .07s linear .07s, opacity 0s linear .14s;
  }
  
  .ham input:checked + svg use:nth-of-type(2) {
    animation: stroke-animation 1.2s ease-out forwards;
  }
  
  
  @keyframes stroke-animation {
    0% {
      stroke-dashoffset: 295;
      stroke-dasharray: 25 270;
    }
    50% {
      stroke-dashoffset: 68;
      stroke-dasharray: 59 236;
    }
    65% {
      stroke-dashoffset: 59;
      stroke-dasharray: 59 236;
    }
    100% {
      stroke-dashoffset: 68;
      stroke-dasharray: 59 236;
    }
  }
  
  @keyframes stroke-animation-reverse {
    0% {
      stroke-dashoffset: 68;
      stroke-dasharray: 59 236;
    }
    50% {
      stroke-dashoffset: 290;
      stroke-dasharray: 25 270;
    }
    65% {
      stroke-dashoffset: 295;
      stroke-dasharray: 25 270;
    }
    100% {
      stroke-dashoffset: 290;
      stroke-dasharray: 25 270;
    }
  }
  

  .glass{
      background: rgba(255, 255, 255, 0.05);
      border-radius: 16px;
      box-shadow: 0 4px 30px rgb(0, 0, 0);
      backdrop-filter: blur(14px);
      -webkit-backdrop-filter: blur(17px);
      border: 1px solid #e7e7e7;
  }



  /* CUSTOM BUTTON STYLE  */

.button {
  position: relative;
  transition: 0.25s;
  z-index: 1;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  background: black;
  color: white;
  border: 3px solid black;
}

.button:before,
.button:after {
  content: "";
  position: absolute;
  z-index: -1;
}

.button:after {
  height: 100%;
  width: 0;
  top: 0;
  background: #E9EAEC;
  transition: 0.15s;
}

.button:hover:after {
  width: 100%;
  color: black;
}

.button.--left:after {
  left: 0;
}

.button.--right:after {
  right: 0;
}

.button:hover{
  color: black;
}


/* loading Animation */

.loader {
  width: 60px;
  display: flex;
  justify-content: space-evenly;
}

.ball {
  list-style: none;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #fff;
}

.ball:nth-child(1) {
  animation: bounce-1 0.5s ease-in-out infinite;
}

@keyframes bounce-1 {
  50% {  
    transform: translateY(-18px);
  }
}

.ball:nth-child(2) {
  animation: bounce-3 0.5s ease-in-out 0.2s infinite;
}

@keyframes bounce-2 {
  50% {
    transform: translateY(-18px);
  }
}

.ball:nth-child(3) {
  animation: bounce-3 0.5s ease-in-out 0.3s infinite;
}

@keyframes bounce-3 {
  50% {
    transform: translateY(-18px);
  }
}






/* TImer */


body-c {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}


.digit-container ul {
  width: 100%;
  overflow: hidden;
}

.digit-container li {
  list-style-type: none;
  font-size: 100px;
  height: 100px;
  width: 100%;
  color: rgba(211, 211, 211, 0.6);
}

#seconds-list, #minute-list, #hour-list {
  transition: all 0.5s ease-in-out;
  transform: translateY(275px);
}

.active-c {
  color: black !important;
  transition: all 0.5s linear;
}


.imgbg{
  background: url("https://minf-minf.s3.ap-south-1.amazonaws.com/bgimage.jpg") no-repeat center/cover;
}


/*------ EYE ANIMATION ------*/
.containercus {
  --color: #a5a5b0;
  --size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: var(--size);
  user-select: none;
  fill: var(--color);
}

.containercus .eye {
  position: absolute;
  animation: keyframes-fill .5s;
}

.containercus .eye-slash {
  position: absolute;
  animation: keyframes-fill .5s;
  display: none;
}

/* ------ On check event ------ */
.containercus input:checked ~ .eye {
  display: none;
}

.containercus input:checked ~ .eye-slash {
  display: block;
}

/* ------ Hide the default checkbox ------ */
.containercus input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* ------ Animation ------ */
@keyframes keyframes-fill {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  50% {
    transform: scale(1.2);
  }
}

/*------ EYE ANIMATION END ------*/
